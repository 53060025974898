<template>
  <section>
    <div class="pa-3">
      <h1 class="page-title">Среднее дневное время бронирования</h1>
    </div>
    <reports-filter
      class="mb-5"
      v-model="filterData"
      @submitFilter="handleSubmitFilter"
      :xslData="xslData"
    />
    <v-data-table
      dense
      class="base-table"
      :headers="headers"
      :items="dataTable"
    />
  </section>
</template>

<script>
import ReportsFilter from "@/components/ReportsFilter.vue";
export default {
  name: "AverageDayTimeBooking",
  components: { ReportsFilter },
  data: () => ({
    headers: [
      {
        text: "Наименование",
        value: "space_name",
      },
      {
        text: "Вместимость",
        value: "capacity",
      },
      {
        text: "Среднее время бронирования в день",
        value: "average_reservation_time",
      },
    ],
    nextDay: new Date(),
    filterData: {
      location: null,
      space_name: null,
      capacity: null,
      interval: [
        `${new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)}`,
        `${new Date(
          Date.now() +
            24 * 60 * 60 * 1000 -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10)}`,
      ],
    },
  }),
  mounted() {
    this.$store.dispatch("fetchAllLocation");
    this.$store.commit("SET_REPORT_TABLE", []);
  },
  computed: {
    dataTable() {
      return this.$store.getters.getReportTable;
    },
    xslData() {
      return this.$store.getters.getReportTable.map((item) => {
        return {
          Наименование: item.space_name,
          Вместимость: item.capacity,
          "Среднее время бронирования в день": item.average_reservation_time,
        };
      });
    },
  },
  methods: {
    handleSubmitFilter() {
      let payload = { ...this.filterData };
      payload.interval = {
        begin: payload.interval[0],
        end:
          payload.interval[1] === payload.interval[0]
            ? this.incrementDate(payload.interval[0])
            : payload.interval[1],
      };

      payload.space_name =
        payload.space_name === "" ? null : payload.space_name;

      this.$store.dispatch("fetchAverageReport", payload);
    },
    incrementDate(date) {
      let resuleDate = new Date(date);
      console.log(resuleDate);
      resuleDate.setDate(resuleDate.getDate() + 1);
      return resuleDate.toISOString().substr(0, 10);
    },
  },
};
</script>

<style lang="scss" scoped></style>
