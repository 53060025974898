<template>
  <div>
    <div class="d-flex justify-center align-end">
      <div class="mr-5" v-if="'space' in paramsFilter">
        <span class="report-filter__title">Пространство</span>
        <v-autocomplete
          clearable
          class="base-auto-complete"
          hide-details
          dense
          append-icon=""
          single-line
          outlined
          v-model="paramsFilter.space"
          :items="listSpaces"
          item-text="name"
          return-object
        ></v-autocomplete>
      </div>
      <div class="mr-5" v-if="'location' in paramsFilter">
        <span class="report-filter__title">Расположение</span>
        <!-- input__location select-input -->
        <v-autocomplete
          clearable
          append-icon=""
          class="base-auto-complete"
          hide-details
          single-line
          dense
          outlined
          v-model="paramsFilter.location"
          :items="listLocations"
          item-text="location"
          item-value="location"
        ></v-autocomplete>
      </div>
      <div class="mr-5" v-if="'space_name' in paramsFilter">
        <span class="report-filter__title">Наименование</span>
        <v-text-field
          class="base-text-field"
          dense
          hide-details
          outlined
          single-line
          v-model="paramsFilter.space_name"
        ></v-text-field>
      </div>
      <div class="mr-5" v-if="paramsFilter.capacity !== undefined">
        <span class="report-filter__title">Вместимость</span>
        <v-text-field
          class="base-text-field"
          dense
          hide-details
          outlined
          single-line
          v-model.number="paramsFilter.capacity"
          @keypress="filter"
        ></v-text-field>
      </div>
      <div class="mr-2" v-if="paramsFilter.interval !== undefined">
        <span class="report-filter__title">Период</span>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- class="report-filter__date-input" -->
            <v-text-field
              class="base-text-field"
              dense
              hide-details
              outlined
              readonly
              single-line
              v-model="dateRangeText"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="paramsFilter.interval" no-title range />
        </v-menu>
      </div>
      <v-btn
        @click="$emit('submitFilter', paramsFilter)"
        class="mr-2 white--text"
        small
        elevation="0"
        color="blue"
        tile
      >
        ОК
      </v-btn>

      <v-btn
        @click="$emit('getFile')"
        small
        tile
        elevation="0"
        color="green"
        class="white--text"
        :disabled="this.$store.getters.getReportTable.length === 0"
        v-if="!this.paramsFilter.space"
      >
        <download-excel v-if="!this.paramsFilter.space" :data="xslData">
          .xlsx
        </download-excel>
        <div v-else>img</div>
      </v-btn>

      <v-btn
        height="24"
        @click="$emit('getFile')"
        small
        elevation="0"
        color="#5FB760"
        tile
        :disabled="
          this.$store.getters.getReportTable.length === 0 || imageUrl !== null
        "
        v-if="this.paramsFilter.space"
        :href="imageUrl"
        download
      >
        jpeg
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportsFilter",
  props: {
    value: {},
    xslData: {},
    imageUrl: null,
  },
  data: () => ({
    menu: false,
  }),
  methods: {
    //filter only numbers
    filter: function(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) evt.preventDefault();
      else return true;
    },
    importXLSX() {},
  },
  computed: {
    dateRangeText() {
      return this.paramsFilter.interval.join("~");
    },
    paramsFilter: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    listLocations() {
      return this.$store.getters.getSpaceLocations;
    },
    listSpaces() {
      return this.$store.getters.getListAllSpaces;
    },
  },
  watch: {
    "paramsFilter.interval": {
      handler(val) {
        console.log("interval", val);
        this.$store.commit("SET_RANGE_TIME", val);
      },
    },
    deep: true,
  },
};
</script>

<style scoped lang="scss">
/* .report-filter {
  &__title {
    font-size: 14px;
  }
  &::v-deep input {
    margin-left: 5px;
  }
} */

/* .report-filter__date-input::v-deep input {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 145px;
} */

/* .input__location::v-deep input {
  width: 220px;
} */

/* .select-input {
  font-size: 12px;
  text-align: center;
} */

/* .select-input::v-deep .v-input__slot {
  border: 1px solid gray;
} */
</style>
